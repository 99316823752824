/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
@import "./assets/styles/variables.scss";
// Import styles
// @import "~@coreui/coreui/scss/coreui";

// If you want to add something do it here
// @import "custom";
// @import "~ngx-toastr/toastr-bs4-alert";

@font-face {
  font-family: "josefin-regular";
  src: url("/assets/fonts/Josefin_Sans/JosefinSans-Regular.woff") format("woff");
}
@font-face {
  font-family: "josefin-bold";
  src: url("/assets/fonts/Josefin_Sans/JosefinSans-Bold.woff") format("woff");
}
@font-face {
  font-family: "josefin-light";
  src: url("/assets/fonts/Josefin_Sans/JosefinSans-Light.woff") format("woff");
}
@font-face {
  font-family: "josefin-medium";
  src: url("/assets/fonts/Josefin_Sans/JosefinSans-Medium.woff") format("woff");
}
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";


body {
  font-family: "josefin-regular";
  background-color: $body-color;
}
.yellow {
  color: $yellow;
}
.cyan {
  color: $cyan;
}
.grey {
  color: $grey;
}

.bluep {
  color: $bluep !important;
}
.main {
  background-color: white !important;
}

h1 {
  font-family: "josefin-light";
  font-style: normal;
  font-weight: 300;
  font-size: 96px;
  line-height: 96px;
  color: $bluep;
}
h2 {
  font-family: "josefin-light";
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
 // line-height: 60px;
  color: $bluep;
}

h3 {
  font-family: "josefin-regular";
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 48px;
  color: $bluep;
}
h4 {
  font-family: "josefin-regular";
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 34px;
  color: $bluep;
}
h5 {
  font-family: "josefin-regular";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  color: $bluep;
}
h6 {
  font-family: "josefin-regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: $text-color;
}

body,
label,
span,
p {
  font-family: "josefin-regular";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: $black;
}

.solidBtn {
  background-color: $yellow;
  width: 323px;
  height: 44px;
  border-radius: 8px;
  border: 0px;
  color: $black;
  //font-family: Josefin Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  margin: 10px;
  margin-left: 0px;
}
.solidBtn:hover {
  color: $white;
}

.solidBtn:focus {
  color: $white;
  background-color: $cyan;
  outline: none;
}
.solidBtn:active {
  color: $white;
  background-color: $cyan;
  box-shadow: none;
}
.solidBtn.disable {
  background-color: $grey;
  color: $white;
  cursor:not-allowed;
}

.lineBtn {
  background-color: $white;
  width: 323px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid $yellow;
  color: $black;
  //font-family: Josefin Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  margin: 10px;
}
.lineBtn:hover {
  color: $yellow;
}
.lineBtn:focus {
  border: 1px solid $cyan;
  outline: none;
}
.lineBtn.disable {
  color: $grey;
  border-color: $grey;
}

.solidBtnIcon {
  background-color: $yellow;
  width: 323px;
  height: 44px;
  border-radius: 8px;
  border: 0px;
  color: $bluep;
  //font-family: Josefin Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  margin: 10px;
}
.solidBtnIcon:hover {
  color: $white;
}

.solidBtnIcon:focus {
  color: $white;
  background-color: $cyan;
  outline: none;
}
.solidBtnIcon:active {
  color: $white;
  background-color: $cyan;
  box-shadow: none;
}
.solidBtnIcon.disable {
  background-color: $grey;
  color: $white;
}
.lineBtnIcon {
  background-color: $white;
  width: 323px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid $yellow;
  color: $black;
  //font-family: Josefin Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  margin: 10px;
}
.lineBtnIcon:hover {
  color: $yellow;
}
.lineBtnIcon:focus {
  border: 1px solid $cyan;
  outline: none;
}
.lineBtnIcon.disable {
  color: $grey;
  border-color: $grey;
}

.inputLblue,
.inputLpink {
  height: 45px;
  width: 232px;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  padding-left: 14px;
}
.greyB {
  background-color: $grey_light;
  color: $bluep;
}

.whiteB {
  background-color: $white;
  color: $bluep;
}

.noBorder {
  border: none;
}
.blueBorder {
  border: 0.5px solid $bluep;
}
.pinkBorder {
  border: 0.5px solid $pink;
}

.inputLblue::placeholder {
  color: $bluep;
}

.inputLpink::placeholder {
  color: $pink;
}

.iconInput {
  background-image: url(/assets/img/inputIcon.png);
  padding-left: calc(2.5em);
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

//home
.rHome {
  margin-top: 5px;
  margin-left: 230px;
  color: #233a80;
  .homeTitle {
    font-family: "josefin-light";
    font-style: normal;
    font-weight: 300;
    font-size: 96px;
    line-height: 96px;
  }
  .lead {
    width: 70%;
    margin-top: 50px;
  }
  a {
    text-decoration: none;
  }
}

.quadrado {
  padding-top: 100px;
  width: 260px;
  height: 285px;
  border: 1px solid rgba(35, 58, 128, 0.5);
  box-sizing: border-box;
}

.quadrado:hover {
  border: 3px solid rgba(247, 190, 0, 1);
 
}
.footerQuad{
  width: 260px;
  height: 30px;
  .row{
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}
.aQuad {
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
}
.colProject{
  min-width: 300px;
}
//header
app-header {
  height: 150px !important;
  border-bottom: none !important;
}
.header-fixed .app-body {
  margin-top: 150px !important;
}
.sidebar-fixed .app-header + .app-body .sidebar {
  height: calc(100vh - 150px) !important;
}
.sidebar-fixed .sidebar {
  // width: 300px !important;
  // background-color: $grey_light !important;
  // color: $bluep !important;
}

.btHome {
  margin-left: 70px;
  background-color: $yellow;
  width: 79px;
  height: 75px;
  border-radius: 50px;
  .imgHome {
    position: static;
    margin-top: 15px;
    margin-left: 17px;
  }
}

.yellowcircle {
  // background-image: url(/assets/img/semicircleyellow.png);
  background-color: $yellow;
  position: fixed !important;
  top: 0;
  right: 0;
  width: 185px;
  height: 164px;
  z-index: -1;
  border-bottom-left-radius: 205px;
}

.nav-link {
  color: $bluep !important;
}

@media (max-width: 768px) {
  app-header {
    img {
      position: fixed !important;
      left: 10% !important;
    }
  }

  .yellowcircle {
    display: none !important;
  }
  .btHome {
    display: none !important;
  }
  .rHome {
    margin-left: 50px;
  }
}

@media (max-width: 1200px) {
  app-header {
    img {
      top: 3% !important;
    }
  }
}

//sidebar
app-sidebar {
  // width: 366px !important;
  // background-color: $grey_light !important;
  // font-family: "josefin-regular";
  // font-style: normal;
  // font-weight: 600;
  // font-size: 14px;
  // line-height: 14px;
  // /* identical to box height */
  // text-transform: uppercase;
  // color: $bluep !important;
}

@media (min-width: 768px) {
  .d-md-block {
    display: none !important;
  }
}

//footer
app-footer {
  border: none !important;
  background-color: white !important;
}
.rFooter {
  width: 100%;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: $bluep;
  }
}

//home
.circlePink {
  background-image: url(/assets/img/semicirclepink.png);
  background-repeat: no-repeat;
  position: fixed !important;
  bottom: 10%;
  right: -100px;
  width: 237px;
  height: 151px;
  z-index: 1;
}
.circleBlue {
  background-image: url(/assets/img/semicircleblue.png);
  background-repeat: no-repeat;
  position: fixed !important;
  top: 230px;
  left: 80px;
  width: 237px;
  height: 151px;
  z-index: 1;
}

//login-callback
.waitPage {
  width: 100%;
  height: 882px;
  color: $yellow;
  background-color: $white !important;
  // opacity: 0.5;
}

//register
.Rregister {
  .circleYellow {
    position: fixed;
    top: 0;
    right: 0;
  }
}

.alertMsg {
  position: fixed;
  top: 0px;
  height: 44px;
  width: 12%;
  padding-left: 15px;
  padding-top: 10px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.ng-invalid.ng-touched {
  border-color: #f05050 !important;
}

.top {
  padding-top: 50px;
}

.headerPage{

  padding-bottom: 2rem;
}

.alignCenter{
  text-align: center;
}

//appcomponent
.header {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  //position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  min-height: 106px;
}
.noPadding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.btFormDisabled{
  text-transform: uppercase;
  min-width: 146px;
  border-radius: 24px;
  border: 1px solid #5E5D5D;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #5E5D5D;
  background-color: transparent;
  min-height: 48px;
  background-color: #E2E2E2;
}
.btForm{
  text-transform: uppercase;
  min-width: 146px;
  border-radius: 24px;
  border: 1px solid $grey;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  color: $bluep;
  background-color: transparent;
  min-height: 48px;
  background-color: $yellow;
}
.btForm:hover{

  cursor: pointer;

  background-color: #fff;
}
.btForm:hover,.btForm:active, .btForm:focus {
  outline: none;
}

.btnFormDone, .btnFormDone:hover{
  border: 0;
  color: $cyan;
  cursor:default;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

.btFormBack{
  background: #E6E6E6 0% 0% no-repeat padding-box;
  border: 1px solid #5E5D5D;
  color: #5E5D5D;
}
.btFormBack:hover{
  border: 1px solid #5E5D5D;
  cursor: pointer;
  color: #E6E6E6;
  background-color: #5E5D5D ;
}

.titleDiv{
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  padding: 3rem;
  background-color: $white;
  .cTitle{
     font-size: 22px;
     font-weight: bold; 
  }
  .cDesc{
      line-height: 1.5;
      font-style: italic;
      padding-top: 40px;
      font-size: 18px; 
      letter-spacing: 0px;
  }
}

.lower-case
{
  text-transform: lowercase;
}

h3{
  font-size: 24px;
  font-weight: bold;
}