//dinamic variables, waiting the values from the api


$header-color: var(--header-color);
$body-color: var(--body-color);
$footer-color: var(--footer-color);
$text-color: var(--text-color);
$menu-color: var(--menu-color);
$icon-color: var(--icon-color);


$yellow: $icon-color;
$cyan: $menu-color;
$grey: #cecece;
$bluep:$text-color;
$black: #000000;
$white: #ffffff;
$grey_light: #efefef;
$pink: #e5004e;

$grey1: #5E5D5D;